<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet tile class="d-flex">
        <v-toolbar flat dense>
          <v-btn dark icon fab small color="accent" class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn dark icon fab small color="accent" class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </v-sheet>
      <v-sheet>
        <v-calendar
          ref="calendar"
          v-model="value"
          type="week"
          locale="fr"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :interval-minutes="intervalDurationInMinutes"
          :first-interval="firstInterval"
          :interval-count="intervalCount"
          color="primary"
          :show-week="true"
          :events="events"
          event-overlap-mode="column"
          :event-color="getEventColor"
          event-text-color="white"
          :event-ripple="false"
          @change="change"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        >
          <template #event="{ event, timed, eventSummary }">
            <div
              class="v-event-draggable"
              v-html="eventSummary()"
            />
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            />
          </template>
          <template #interval="{ weekday, hour, minute }">
            <div v-if="isClosed(String(weekday === 0 ? 7 : weekday), String(hour), String(minute))" class="background-stripes" />
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
  import { DateTime } from 'luxon';
  import { hhmmssToObject, MONTH } from '@/util/DateUtil';

  export default {
    props: {
      openingHours: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      title: '',
      firstInterval: 0,
      intervalDurationInMinutes: 30,
      intervalCount: 24 * 2,
      closingIntervals: {},

      value: '',
      events: [],
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null
    }),
    watch: {
      openingHours: function () {
        this.updateInterval();
        this.updateClosingIntervals();
      }
    },
    mounted () {
      this.updateTitle();
      this.updateInterval();
      this.updateClosingIntervals();
    },
    methods: {
      updateTitle (startDate) {
        if (startDate === undefined) {
          startDate = String(this.$refs.calendar.start);
        }

        const dateTime = DateTime.fromISO(startDate).setZone('Europe/Paris');
        this.title = MONTH[dateTime.month] + ' ' + dateTime.year + ' - ' + this.$i18n.t('common.calendar.week') + ' ' + dateTime.weekNumber;
      },
      updateInterval () {
        let firstOpenHour;
        let lastCloseHour;
        this.openingHours.forEach((openingHour) => {
          const open = hhmmssToObject(openingHour.openTime);
          const openHours = open.hour + (open.minute >= this.intervalDurationInMinutes ? (this.intervalDurationInMinutes / 60) : 0);
          const close = hhmmssToObject(openingHour.closeTime);
          const closeHours = close.hour + (this.intervalDurationInMinutes / 60) + (close.minute >= this.intervalDurationInMinutes ? (this.intervalDurationInMinutes / 60) : 0);

          if (firstOpenHour === undefined || firstOpenHour > openHours) {
            firstOpenHour = openHours;
          }
          if (lastCloseHour === undefined || lastCloseHour < closeHours) {
            lastCloseHour = closeHours;
          }
        });
        this.firstInterval = (firstOpenHour ?? 0) * (60 / this.intervalDurationInMinutes);
        this.intervalCount = (lastCloseHour ?? 24) * (60 / this.intervalDurationInMinutes) - this.firstInterval - 1;
      },
      updateClosingIntervals () {
        this.closingIntervals = {};
        this.openingHours.forEach((openingHour) => {
          if (this.closingIntervals[openingHour.weekday] === undefined) {
            this.closingIntervals[String(openingHour.weekday)] = {};
          }
          let date = DateTime.fromFormat(openingHour.openTime, 'HH:mm:ss');
          while (date.toFormat('HH:mm:ss') < openingHour.closeTime) {
            if (this.closingIntervals[String(openingHour.weekday)][String(date.hour)] === undefined) {
              this.closingIntervals[String(openingHour.weekday)][String(date.hour)] = {};
            }
            this.closingIntervals[String(openingHour.weekday)][String(date.hour)][String(date.minute)] = 'opened';

            date = date.plus({ minutes: this.intervalDurationInMinutes });
          }
        });
      },
      isClosed (weekday, hour, minute) {
        return this.closingIntervals[weekday] === undefined
          || this.closingIntervals[weekday][hour] === undefined
          || this.closingIntervals[weekday][hour][minute] === undefined;
      },
      change ({ start, end }) {
        this.updateTitle(start.date);
        this.getEvents({ start, end });
      },
      startDrag ({ event, timed }) {
        if (event && timed) {
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      },
      startTime (tms) {
        const mouse = this.toTime(tms);

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start;

          this.dragTime = mouse - start;
        } else {
          this.createStart = this.roundTime(mouse);
          this.createEvent = {
            name: `Event #${this.events.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart,
            timed: true
          };

          this.events.push(this.createEvent);
        }
      },
      extendBottom (event) {
        this.createEvent = event;
        this.createStart = event.start;
        this.extendOriginal = event.end;
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms);

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;

          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false);
          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);

          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      },
      endDrag () {
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      },
      cancelDrag () {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal;
          } else {
            const i = this.events.indexOf(this.createEvent);
            if (i !== -1) {
              this.events.splice(i, 1);
            }
          }
        }

        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
      },
      roundTime (time, down = true) {
        const roundTo = 15; // minutes
        const roundDownTime = roundTo * 60 * 1000;

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime));
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
      },
      getEventColor (event) {
        const rgb = parseInt(event.color.substring(1), 16);
        const r = (rgb >> 16) & 0xFF;
        const g = (rgb >> 8) & 0xFF;
        const b = (rgb >> 0) & 0xFF;

        return event === this.dragEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event === this.createEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event.color;
      },
      getEvents ({ start, end }) {
        const events = [];

        // console.log(start);
        // console.log(end);

        // const min = new Date(`${start.date}T00:00:00`).getTime();
        // const max = new Date(`${end.date}T23:59:59`).getTime();
        // const days = (max - min) / 86400000;
        // const eventCount = this.rnd(days, days + 20);
        //
        // for (let i = 0; i < eventCount; i++) {
        //   const timed = this.rnd(0, 3) !== 0;
        //   const firstTimestamp = this.rnd(min, max);
        //   const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        //   const start = firstTimestamp - (firstTimestamp % 900000);
        //   const end = start + secondTimestamp;
        //
        //   events.push({
        //     name: this.rndElement(this.names),
        //     color: this.rndElement(this.colors),
        //     start,
        //     end,
        //     timed
        //   });
        // }

        this.events = events;
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)];
      }
    }
  };
</script>
