<template>
  <v-container id="usersSchedule" fluid tag="section">
    <alert-notification :message="alert" />
    <h1 class="text-center text-uppercase">
      {{ $t('admin.sub.usersSchedule.lunch') }}
    </h1>
    <calendar :opening-hours="openingHoursAtLunchtime" />
    <h1 class="text-center text-uppercase">
      {{ $t('admin.sub.usersSchedule.evening') }}
    </h1>
    <calendar :opening-hours="openingHoursAtDinertime" />
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import Calendar from '@/views/components/common/Calendar';

  export default {
    name: 'UsersSchedule',
    components: { AlertNotification, Calendar },
    data () {
      return {
        alert: '',
        loading: false,
        openingHoursAtLunchtime: [],
        openingHoursAtDinertime: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/hours/business');
          if (response.data !== undefined && response.data.openingHours !== undefined) {
            this.openingHoursAtLunchtime = response.data.openingHours.filter((openingHour) => openingHour.openTime < '16:00:00');
            this.openingHoursAtDinertime = response.data.openingHours.filter((openingHour) => openingHour.openTime >= '17:00:00');
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
